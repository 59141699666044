.mentionSuggestions {
  position: absolute;
  width: 240px;
  padding: 0;
  border: 1px solid #f4f3f6;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 4px 8px 24px rgba(87, 86, 97, 0.1);
  display: flex;
  flex-direction: column;
  background: #fff;
  cursor: pointer;
  z-index: 2;
}
