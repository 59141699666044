:root {
  --icon-Icon_Medium_Social_Edmunds: '%ue692%ue17e%ue089';
  --icon-Icon_Medium_Social_Google: '%ue390%ue575%ue008';
  --icon-Icon_Medium_Module_Inbox: '%ue000%ue27a%ue1d2%ue04a';
  --icon-Icon_Medium_Module_People: '%ue40e%ue0ad%ue07f';
  --icon-Icon_Medium_Social_Facebook: '%ue706%ue576%ue015';
  --icon-Icon_Medium_Module_Dashboard: '%ue000%ue31c%ue58a%ue0bc';
  --icon-Icon_Medium_Social_Yelp: '%ue000%ue75f%ue453%ue003';
  --icon-Icon_Medium_Module_Organizations: '%ue000%ue37f%ue6fc%ue030';
  --icon-Icon_Medium_Misc_Close: '%ue000%ue6c3%ue4cf%ue058';
  --icon-Icon_Medium_Arrows_Unchanged: '%ue55d%ue18c%ue04b';
  --icon-Icon_Medium_Misc_Delete: '%ue000%ue173%ue458%ue0e7';
  --icon-Icon_Medium_Misc_Done_circle: '%ue000%ue5e6%uecf5%ue0e3';
  --icon-Icon_Medium_Arrows_Decrease: '%ue6aa%ue0fe%ue070';
  --icon-Icon_Medium_Misc_Plus_circle: '%ue7bb%uee97%ue007';
  --icon-Icon_Medium_Misc_Edit: '%ue4f2%ue861%ue03a';
  --icon-Icon_Medium_Arrows_Increase: '%ue4e1%ueef4%ue014';
  --icon-Icon_Medium_Misc_Search_left: '%ue6ac%ue248%ue030';
  --icon-Icon_Medium_Misc_Done_mini: '%ue693%ue4af%ue026';
  --icon-Icon_Small_Submodule_Users: '%uedeb%ue120%ue008';
  --icon-Icon_Small_Misc_Pin_fill_rounded_circle: '%ue000%ue6ad%ue002%ue062';
  --icon-Icon_Small_Misc_Information: '%ue000%ue54c%ue643%ue0ea';
  --icon-Icon_Small_Misc_Edit_fill: '%ue000%ue7d6%ue42c%ue00e';
  --icon-Icon_Small_Misc_Filter: '%ue000%ue219%ue304%ue054';
  --icon-Icon_Small_Misc_Star_favorite_fill: '%ue000%ue7ac%ue110%ue033';
  --icon-Icon_Small_Misc_Underline: '%ue2a4%ue40e%ue078';
  --icon-Icon_Small_Arrows_Chevron_down: '%ue000%ue693%ue077%ue0c4';
  --icon-Icon_Small_Arrows_Down_mini: '%ue000%ue43c%ue250%ue0e4';
  --icon-Icon_Small_Misc_Star_favorite: '%ue000%ue611%ue116%ue0ed';
  --icon-Icon_Small_Arrows_Up_mini: '%ue127%ue319%ue005';
  --icon-Icon_Small_Misc_Menu_option: '%ue000%ue6f3%ue741%ue0c1';
  --icon-Icon_Small_Arrows_Arrow_up: '%ue000%ue594%uecb9%ue0d9';
  --icon-Icon_Small_Submodule_Customers: '%ue511%ue071%ue0c5';
  --icon-Icon_Small_Misc_Done_fill_circle: '%ue000%ue728%ueaa4%ue03d';
  --icon-Icon_Small_Arrows_Arrow_down: '%ue307%ue6df%ue010';
  --icon-Icon_Small_Misc_Medal: '%ue000%ue198%uea3d%ue0df';
  --icon-Icon_Small_Misc_Settings: '%ue000%ue4c6%ue08c%ue0e9';
  --icon-Icon_Small_Misc_Folder: '%ue000%ue213%ueeb3%ue05e';
  --icon-Icon_Small_Social_Yelp_star: '%ue000%ue2b4%uefa4%ue0f4';
  --icon-Icon_Small_Misc_Calendar: '%ue539%uebb4%ue034';
  --icon-Icon_Small_Misc_Email: '%ue000%ue19f%ue761%ue03a';
  --icon-Icon_Small_Submodule_Directory: '%ue000%ue487%uedee%ue025';
  --icon-Icon_Small_Social_Recommended: '%ue000%ue30f%ue3e1%ue03a';
  --icon-Icon_Small_Misc_Search_left_small: '%ue29a%uebfa%ue072';
  --icon-Icon_Small_Arrows_Chevron_up: '%ue453%ue6de%ue0f7';
  --icon-Icon_Small_Social_Facebook_small: '%ue000%ue75d%uede3%ue09f';
  --icon-Icon_Small_Social_Yelp_small: '%ue000%ue3cc%ue80a%ue055';
  --icon-Icon_Small_Social_Google_small: '%ue490%uecf4%ue096';
  --icon-Icon_Small_Social_Edmunds_small: '%ue000%ue67f%ueea1%ue077';
  --icon-Logo_Icon_Gray: '%ue000%ue140%ue6d0%ue00c';
  --icon-Logo_Icon_Color: '%ue000%ue6b2%ueca5%ue0f0';
  --icon-Logo_Icon_White: '%ue000%ue6a1%ue609%ue02a';
  --icon-BG: '%ue846';
  --icon-Icon_Small_Arrows_Arrow_right: '%ue5e9%ue9a3%ue070';
  --icon-Icon_Small_Misc_Plus_mini: '%uef3f%ue069%ue008';
  --icon-Icon_Small_Misc_Number_sign_mini: '%ue000%ue763%uef0f%ue009';
  --icon-Icon_Small_Misc_Profile_round_mini: '%ue000%ue629%uecaa%ue08e';
  --icon-Icon_Medium_Misc_Camera: '%ue000%ue192%ue11b%ue06d';
  --icon-Icon_Medium_Misc_Visible: '%ue000%uedff%uee42%ue00e';
  --icon-Icon_Medium_Misc_Invisible: '%ue000%ue6a5%ue92e%ue023';
  --icon-Icon_Medium_Misc_Warning: '%ue194%ue4dc%ue00e';
  --icon-Icon_Medium_Misc_Info: '%ue4f2%uea57%ue0de';
  --icon-Icon_Medium_Misc_Info_mini: '%ue508%ue849%ue07a';
  --icon-Icon_Medium_Misc_Warning_mini: '%ue584%ue8e3%ue04a';
  --icon-Icon_Medium_Misc_Error_mini: '%ue3cc%ue364%ue01e';
  --icon-Icon_Small_Misc_Delete: '%ue000%ue253%ue54a%ue021';
  --icon-Icon_Small_Misc_Image: '%ue000%ue19b%uef03%ue07b';
  --icon-Icon_Small_Misc_Camera: '%ue000%ue272%ue20c%ue0a7';
  --icon-Icon_Medium_Misc_Image: '%ue000%ue6be%ue003%ue0f5';
  --icon-Icon_Medium_Misc_Plus_mini: '%ue000%ue512%uea2d%ue0f4';
  --icon-Icon_Medium_Misc_Minus: '%ue000%ue6ba%ue944%ue0e0';
  --icon-Icon_Medium_Arrows_Arrow_log-out: '%ue000%ue228%ue694%ue023';
  --icon-Icon_Medium_Misc_Error_circle: '%ue000%ue33b%ue79e%ue0eb';
  --icon-Icon_Small_Arrows_Arrow_left: '%ue30a%uee96%ue005';
  --icon-Icon_Medium_Module_Inventory: '%ue000%ue6bc%ue05b%ue0f4';
  --icon-Icon_Small_Misc_Clipboard: '%ue807%ue245%ue002';
  --icon-Icon_Small_Misc_View_simple: '%ue3de%ue0e9%ue078';
  --icon-Icon_Small_Misc_Click: '%ue000%ue1a1%ue3e7%ue04e';
}
.icon {
  font-family: 'ResponsePro';
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.icon.social-edmunds::before {
  content: '%ue692%ue17e%ue089';
}
.icon.social-google::before {
  content: '%ue390%ue575%ue008';
}
.icon.module-inbox::before {
  content: '%ue000%ue27a%ue1d2%ue04a';
}
.icon.module-people::before {
  content: '%ue40e%ue0ad%ue07f';
}
.icon.social-facebook::before {
  content: '%ue706%ue576%ue015';
}
.icon.module-dashboard::before {
  content: '%ue000%ue31c%ue58a%ue0bc';
}
.icon.social-yelp::before {
  content: '%ue000%ue75f%ue453%ue003';
}
.icon.module-organizations::before {
  content: '%ue000%ue37f%ue6fc%ue030';
}
.icon.misc-close::before {
  content: '%ue000%ue6c3%ue4cf%ue058';
}
.icon.arrows-unchanged::before {
  content: '%ue55d%ue18c%ue04b';
}
.icon.misc-delete::before {
  content: '%ue000%ue173%ue458%ue0e7';
}
.icon.misc-done-circle::before {
  content: '%ue000%ue5e6%uecf5%ue0e3';
}
.icon.arrows-decrease::before {
  content: '%ue6aa%ue0fe%ue070';
}
.icon.misc-plus-circle::before {
  content: '%ue7bb%uee97%ue007';
}
.icon.misc-edit::before {
  content: '%ue4f2%ue861%ue03a';
}
.icon.arrows-increase::before {
  content: '%ue4e1%ueef4%ue014';
}
.icon.misc-search-left::before {
  content: '%ue6ac%ue248%ue030';
}
.icon.misc-done-mini::before {
  content: '%ue693%ue4af%ue026';
}
.icon.icon-small-submodule-users::before {
  content: '%uedeb%ue120%ue008';
}
.icon.icon-small-misc-pin-fill-rounded-circle::before {
  content: '%ue000%ue6ad%ue002%ue062';
}
.icon.icon-small-misc-information::before {
  content: '%ue000%ue54c%ue643%ue0ea';
}
.icon.icon-small-misc-edit-fill::before {
  content: '%ue000%ue7d6%ue42c%ue00e';
}
.icon.icon-small-misc-filter::before {
  content: '%ue000%ue219%ue304%ue054';
}
.icon.icon-small-misc-star-favorite-fill::before {
  content: '%ue000%ue7ac%ue110%ue033';
}
.icon.icon-small-misc-underline::before {
  content: '%ue2a4%ue40e%ue078';
}
.icon.icon-small-arrows-chevron-down::before {
  content: '%ue000%ue693%ue077%ue0c4';
}
.icon.icon-small-arrows-down-mini::before {
  content: '%ue000%ue43c%ue250%ue0e4';
}
.icon.icon-small-misc-star-favorite::before {
  content: '%ue000%ue611%ue116%ue0ed';
}
.icon.icon-small-arrows-up-mini::before {
  content: '%ue127%ue319%ue005';
}
.icon.icon-small-misc-menu-option::before {
  content: '%ue000%ue6f3%ue741%ue0c1';
}
.icon.icon-small-arrows-arrow-up::before {
  content: '%ue000%ue594%uecb9%ue0d9';
}
.icon.icon-small-submodule-customers::before {
  content: '%ue511%ue071%ue0c5';
}
.icon.icon-small-misc-done-fill-circle::before {
  content: '%ue000%ue728%ueaa4%ue03d';
}
.icon.icon-small-arrows-arrow-down::before {
  content: '%ue307%ue6df%ue010';
}
.icon.icon-small-misc-medal::before {
  content: '%ue000%ue198%uea3d%ue0df';
}
.icon.icon-small-misc-settings::before {
  content: '%ue000%ue4c6%ue08c%ue0e9';
}
.icon.icon-small-misc-folder::before {
  content: '%ue000%ue213%ueeb3%ue05e';
}
.icon.icon-small-social-yelp-star::before {
  content: '%ue000%ue2b4%uefa4%ue0f4';
}
.icon.icon-small-misc-calendar::before {
  content: '%ue539%uebb4%ue034';
}
.icon.icon-small-misc-email::before {
  content: '%ue000%ue19f%ue761%ue03a';
}
.icon.icon-small-submodule-directory::before {
  content: '%ue000%ue487%uedee%ue025';
}
.icon.icon-small-social-recommended::before {
  content: '%ue000%ue30f%ue3e1%ue03a';
}
.icon.icon-small-misc-search-left-small::before {
  content: '%ue29a%uebfa%ue072';
}
.icon.icon-small-arrows-chevron-up::before {
  content: '%ue453%ue6de%ue0f7';
}
.icon.icon-small-social-facebook-small::before {
  content: '%ue000%ue75d%uede3%ue09f';
}
.icon.icon-small-social-yelp-small::before {
  content: '%ue000%ue3cc%ue80a%ue055';
}
.icon.icon-small-social-google-small::before {
  content: '%ue490%uecf4%ue096';
}
.icon.icon-small-social-edmunds-small::before {
  content: '%ue000%ue67f%ueea1%ue077';
}
.icon.logo-icon-gray::before {
  content: '%ue000%ue140%ue6d0%ue00c';
}
.icon.logo-icon-color::before {
  content: '%ue000%ue6b2%ueca5%ue0f0';
}
.icon.logo-icon-white::before {
  content: '%ue000%ue6a1%ue609%ue02a';
}
.icon.bg::before {
  content: '%ue846';
}
.icon.icon-small-arrows-arrow-right::before {
  content: '%ue5e9%ue9a3%ue070';
}
.icon.icon-small-misc-plus-mini::before {
  content: '%uef3f%ue069%ue008';
}
.icon.icon-small-misc-number-sign-mini::before {
  content: '%ue000%ue763%uef0f%ue009';
}
.icon.icon-small-misc-profile-round-mini::before {
  content: '%ue000%ue629%uecaa%ue08e';
}
.icon.misc-camera::before {
  content: '%ue000%ue192%ue11b%ue06d';
}
.icon.misc-visible::before {
  content: '%ue000%uedff%uee42%ue00e';
}
.icon.misc-invisible::before {
  content: '%ue000%ue6a5%ue92e%ue023';
}
.icon.misc-warning::before {
  content: '%ue194%ue4dc%ue00e';
}
.icon.misc-info::before {
  content: '%ue4f2%uea57%ue0de';
}
.icon.misc-info-mini::before {
  content: '%ue508%ue849%ue07a';
}
.icon.misc-warning-mini::before {
  content: '%ue584%ue8e3%ue04a';
}
.icon.misc-error-mini::before {
  content: '%ue3cc%ue364%ue01e';
}
.icon.icon-small-misc-delete::before {
  content: '%ue000%ue253%ue54a%ue021';
}
.icon.icon-small-misc-image::before {
  content: '%ue000%ue19b%uef03%ue07b';
}
.icon.icon-small-misc-camera::before {
  content: '%ue000%ue272%ue20c%ue0a7';
}
.icon.misc-image::before {
  content: '%ue000%ue6be%ue003%ue0f5';
}
.icon.misc-plus-mini::before {
  content: '%ue000%ue512%uea2d%ue0f4';
}
.icon.misc-minus::before {
  content: '%ue000%ue6ba%ue944%ue0e0';
}
.icon.arrows-arrow-log-out::before {
  content: '%ue000%ue228%ue694%ue023';
}
.icon.misc-error-circle::before {
  content: '%ue000%ue33b%ue79e%ue0eb';
}
.icon.icon-small-arrows-arrow-left::before {
  content: '%ue30a%uee96%ue005';
}
.icon.module-inventory::before {
  content: '%ue000%ue6bc%ue05b%ue0f4';
}
.icon.icon-small-misc-clipboard::before {
  content: '%ue807%ue245%ue002';
}
.icon.icon-small-misc-view-simple::before {
  content: '%ue3de%ue0e9%ue078';
}
.icon.icon-small-misc-click::before {
  content: '%ue000%ue1a1%ue3e7%ue04e';
}
