@import '~antd/dist/antd.css';
@import '../assets/fonts/responsepro-webfont/stylesheet.css';
@import '../assets/fonts/responsepro-webfont/icons.css';
html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

[data-icon]:before {
  font-family: 'ResponsePro';
  content: attr(data-icon);
  speak-as: none;
}

.ic {
  display: inline-block;
  height: 16px;
  width: 16px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.ic_google {
  background-image: url(/src/assets/icons/ic_google.svg);
}
.ic_google_filled {
  background-image: url(/src/assets/icons/ic_google_filled.svg);
}
.ic_google_grey {
  background-image: url(/src/assets/icons/ic_google_grey.svg);
}
.ic_facebook {
  background-image: url(/src/assets/icons/ic_facebook.svg);
}

.ic_tagmanager {
  background-image: url(/src/assets/icons/ic_tagmanager.svg);
}

.ic_google_ads_grey {
  background-image: url(/src/assets/icons/ic_google_ads_grey.svg);
}

.ic_google_ads_filled {
  background-image: url(/src/assets/icons/ic_google_ads_filled.svg);
}

.ic_fb_ads_filled {
  background-image: url(/src/assets/icons/ic_fb_ads_filled.svg);
}

.ic_fb_ads_grey {
  background-image: url(/src/assets/icons/ic_fb_ads_grey.svg);
}

.ic_tiktok {
  background-image: url(/src/assets/icons/ic_tiktok.svg);
}

.ic_tiktok_filled {
  background-image: url(/src/assets/icons/ic_tiktok_filled.svg);
}

.ic_tiktok_grey {
  background-image: url(/src/assets/icons/ic_tiktok_grey.svg);
}

.ic_facebook_filled {
  background-image: url(/src/assets/icons/ic_facebook_filled.svg);
}
.ic_facebook_grey {
  background-image: url(/src/assets/icons/ic_facebook_grey.svg);
}
.ic_fb_marketplace_filled {
  background-image: url(/src/assets/icons/ic_fb_marketplace_filled.svg);
}
.ic_fb_marketplace_grey {
  background-image: url(/src/assets/icons/ic_fb_marketplace_grey.svg);
}
.ic_arrow_right {
  background-image: url(/src/assets/icons/ic_arrow_right.svg);
}
.ic_google_white {
  background-image: url(/src/assets/icons/ic_google_white.svg);
}
.ic_email {
  background-image: url(/src/assets/icons/ic_email.svg);
}
.ic_localShift {
  margin-top: 10px;
  background-image: url(/src//assets/icons/ic_localShift.svg);
  width: 32px;
  height: 32px;
}
.ic_instagram {
  background-image: url(/src/assets/icons/ic_instagram.svg);
  width: 32px;
  height: 32px;
}
.ic_ig {
  background-image: url(/src/assets/icons/ic_ig.svg);
  width: 16px;
  height: 16px;
}

.ic_ig_selected {
  background-image: url(/src/assets/icons/ic_ig_selected.svg);
  width: 16px;
  height: 16px;
}

.ic_ig_filled {
  background-image: url(/src/assets/icons/ic_ig_filled.svg);
}
.ic_ig_grey {
  background-image: url(/src/assets/icons/ic_ig_grey.svg);
}

.ic_arrow_reload {
  background-image: url(/src/assets/icons/ic_arrow_repeat.svg);
  width: 24px;
  height: 24px;
}
.ic_warning_filled {
  background-image: url(/src/assets/icons/ic_warning_filled.svg);
}
